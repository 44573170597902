import React from "react";
import { ActionButton } from "./components/action-button";
import { Social } from "./components/social";
import { TopWave } from "./components/top-wave";

import logo from "./assets/HB-Logo-Dark-Colored.svg";
import "./about.scss";
import { CtaFooter } from "./components/cta-footer";
import { BottomWave } from "./components/bottom-wave";

interface Props {
  children: any;
}

export const Notice = ({ children }: Props) => {
  return (
    <>
      <div className="notice--wrapper">
        <div className="notice--wrapper--core--bg">
          <TopWave type="wave" />
          <div className="notice--wrapper--core">
            <div className="notice--wrapper--inner">{children}</div>
          </div>
        </div>
        {/* <div className="notice--additional">
          <div className="cta-footer--content">
            <p className="cta--subtitle" style={{ textAlign: "center" }}>
              Need a custom tool?
            </p>
            <ActionButton
              text="Reach out today!"
              variant="solid"
              colorScheme="primary"
            />
          </div>
        </div> */}
        <CtaFooter noBg={true} />
      </div>
      <Social />
    </>
  );
};

export default Notice;
